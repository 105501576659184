/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on November 19, 2022 */





@font-face {
    font-family: 'suisse_intlblack';
    src: url('../fonts/suisseintl-black-webfont.woff2') format('woff2'),
         url('../fonts/suisseintl-black-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'suisse_intlblack_italic';
    src: url('../fonts/suisseintl-blackitalic-webfont.woff2') format('woff2'),
         url('../fonts/suisseintl-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'suisse_intlbold';
    src: url('../fonts/suisseintl-bold-webfont.woff2') format('woff2'),
         url('../fonts/suisseintl-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'suisse_intlbold_italic';
    src: url('../fonts/suisseintl-bolditalic-webfont.woff2') format('woff2'),
         url('../fonts/suisseintl-bolditalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'suisse_intlbook_italic';
    src: url('../fonts/suisseintl-bookitalic-webfont.woff2') format('woff2'),
         url('../fonts/suisseintl-bookitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'suisse_intllight';
    src: url('../fonts/suisseintl-light-webfont.woff2') format('woff2'),
         url('../fonts/suisseintl-light-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'suisse_intllight_italic';
    src: url('../fonts/suisseintl-lightitalic-webfont.woff2') format('woff2'),
         url('../fonts/suisseintl-lightitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'suisse_intlmedium';
    src: url('../fonts/suisseintl-medium-webfont.woff2') format('woff2'),
         url('../fonts/suisseintl-medium-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}




@font-face {
    font-family: 'suisse_intlmedium_italic';
    src: url('../fonts/suisseintl-mediumitalic-webfont.woff2') format('woff2'),
         url('../fonts/suisseintl-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'suisse_intlregular';
    src: url('../fonts/suisseintl-regular-webfont.woff2') format('woff2'),
         url('../fonts/suisseintl-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'suisse_intlitalic';
    src: url('../fonts/suisseintl-regularitalic-webfont.woff2') format('woff2'),
         url('../fonts/suisseintl-regularitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

    
}

@font-face {
    font-family: 'suisse_intlbook';
    src: url('../fonts/suisseintl-book-webfont.woff2') format('woff2'),
         url('../fonts/suisseintl-book-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'suisse_intlbook_italic';
    src: url('../fonts/suisseintl-bookitalic-webfont.woff2') format('woff2'),
         url('../fonts/suisseintl-bookitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'suisse_intlthin';
    src: url('../fonts/suisseintl-thin-webfont.woff2') format('woff2'),
         url('../fonts/suisseintl-thin-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'suisse_intlultralight';
    src: url('../fonts/suisseintl-ultralight-webfont.woff2') format('woff2'),
         url('../fonts/suisseintl-ultralight-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


@font-face {
    font-family: 'suisse_intl_monoregular';
    src: url('../fonts/suisse-intl-mono-webfont.woff2') format('woff2'),
         url('../fonts/suisse-intl-mono-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}