@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&family=Noto+Sans:wght@600;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@900&display=swap');
:root{
  --font12:.75rem;
  --font14:0.875rem;
  --font16:1rem;
  --font18:1.125rem;
  --font20:1.25rem;
  --font22:1.375rem;
  --font24:1.5rem;
  --font26:1.625rem;
  --font28:1.75rem;
  --font30:1.875rem;
  --font32:2rem;
  --font34:2.125rem;
  --font36:1.25rem;
  --font38:2.375rem;
  --font40:2.5rem;
  --font48:3rem;

  --bluecolor:#2A92F3;
  --primary:#EC360F;
  --white:#fff;
  --secondery:#263238;
  --black:#000;
  --darkGray:#1D1C21;
  --gray1:#FAF7FA;
  --gray2:#E6E6E6;
  --fontLight:'suisse_intllight';
  --fontRegular:'suisse_intlregular';
  --fontMedium:'suisse_intlmedium';
  --fontBold:'suisse_intlblack';
  --fontbold2:'suisse_intlbold'


}
html{ font-size: calc(.60vh + .60vw);}
body{font-family: 'Montserrat', sans-serif !important;}
.logoBox img{width: 13rem;  transition: all .3s ease-in-out;}
.btn{padding: .65rem 1.875rem; font-size: var(--font16) !important;  font-weight: 700; border-radius: 2rem !important; transition: all .3s ease-in-out;}
.btn-primary{background-color: var(--primary); border-color: transparent; color: var(--white); } 


/* .btn-primary:focus,  .btn-outline-primary:focus{} */
.btn-primary:hover, .btn-outline-primary:hover{background-color: var(--bluecolor);color: var(--white); border-color: var(--bluecolor);} 

.btn-outline-primary{ border-color: var(--primary); color: var(--primary) ;}
.logoBox{font-size: 23px !important; color: var(--black); font-weight: 500; display: flex; align-items: center; gap: 0.9375rem; transition: all .3s ease-in-out;}

.navbarNew a{font-size: 1rem; font-weight: 400; color: var(--black); padding: 0.625rem 0.625rem !important; font-weight: 600; position: relative;}
.navbarNew .nav-link:hover{color: var(--bluecolor);}
.navbarNew li{position: relative;}
.navbarNew li::before{content: '';
  width: 0;
  display: none;
  height: 4px;
  background: var(--black);
  border-radius: 3px;
  left: 0;
  right: 0;
  margin: auto;
  bottom: -11px;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: all .3s ease-in-out;}
  .howterpnashWorks{position:relative}
  .star{position:absolute; left:15rem}
  .stars{position: absolute; right: 17rem;}
  .star1{position:absolute; top:12rem; right:30rem}
  .star-bottom{position:absolute; bottom: 4rem;left:15rem}
  .navbarNew li:hover::before{visibility: visible;
    opacity: 1;
    width: 50px;}
.headerbx{box-shadow: 0px 4px 13.800000190734863px 0px #0000000D;  background: #fff; backdrop-filter: blur(2px); position: fixed; left: 0; top: 0; z-index: 10;}
.headerbx::before{content: ''; width: 100%; height: 100%; background: linear-gradient(90deg, #EC360F, #87A5CD); z-index: -11; position: absolute; top: 0; left: 0; opacity: .46;}
/**/
.bannerBox{width: 100%; position: relative; z-index: 0; padding-top: 5rem; padding-bottom: 4.5625rem;  }
.bannerBox::before{content: ''; background: linear-gradient(90deg, #F62C00, #4992D9); position: absolute; top: 0; left: 0; width: 100%; height: 100%;     opacity: .66; z-index: -1;}   
.bannerBox::after{content: ''; background: url('./assets/images/bannerlandingimage.jpg') no-repeat; background-size:cover;  position: absolute; top: 0; left: 0; width: 100%; height: 100%;     opacity: .30; z-index: -2;}   
.stickyHeader{background-color: #fff;}
.stickyHeader .logoBox img{width: 10rem;}
.stickyHeader .logoBox{font-size: var(--font22);}
.stickyHeader a.btn{font-size: var(--font14) !important;}
.btnm{
  display:flex;
}
.btnm .a{
  padding:20px;
}
/* .stickyHeader .headerbx::before{opacity: .9;} */

.navbarNew .nav-link:focus, .navbarNew .nav-link:hover{color: var(--primary);}
/* banner */
.bannertext{text-align: left; }
.bannertext h1{font-size: var(--font38); color: var(--white); font-style: normal; font-weight: 800;  line-height: normal;}
.bannertext h3{color: var(--white);  font-size: 5rem;  font-style: normal;  font-weight: 900;  line-height: normal; letter-spacing: 0px; 

}
.bannertext p{font-size: var(--font26); color: var(--white); width: 80%; margin-bottom: 2rem;}
.bannerbtns{display: flex; align-items: center; justify-content: center; gap: 1rem;}

.sliderBox {
  /* background-color: var(--black); */
 
  width: 82%;  margin: auto;
}
.sliderBox img{width: 100%;}
.sliderBox .image-gallery-slide img {
  width: 100%;
  height: auto;
  height: 49.5vh;
  object-fit: cover;
  overflow: hidden;
  object-position: center center;
}

/* .image-gallery-slide-wrapper {
  position: relative;
  background: url('./assets/images/jpg/tvbackgraound.png') no-repeat;
  padding: 0.8125rem;
  background-size: contain;}
  .image-gallery-slide-wrapper::before {
    width: 100%;
    height: 4.5625rem;
    content: '';
    background: url('./assets/images/jpg/tvstand.png') no-repeat center;
    position: absolute;
    top: 99%;
    left: 0%;     background-size: contain;
    right: 0;
    margin: auto;
    } */

  .sliderBoxNew{
  width: 90%; 
  position: relative;
  background: url('./assets/images/jpg/tvbackgraound.png') no-repeat;
  padding: 0.65rem;
  background-size: cover; margin: auto;
  }
  .sliderBoxNew img{width: 100%; height: 100%;}
  .sliderBoxNew::before {
    width: 100%;
    height: 4.5625rem;
    content: '';
    background: url('./assets/images/jpg/tvstand.png') no-repeat center;
    position: absolute;
    top: 100%;
    left: 0%;     background-size: 100% 100%;
    right: 0;
    margin: auto;
    }

.image-gallery-thumbnails-wrapper{padding: 0 40px;}
.image-gallery-slide-wrapper button{display:none;}
.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg{width: 13px !important; height: auto !important;}
.image-gallery-left-nav, .image-gallery-right-nav{padding: 0 !important; top: inherit !important; bottom: 0px !important; transform: translateY(53px) !important;     width: 30px;
height: 30px; background: #000 !important;
border-radius: 30px;}
.image-gallery-left-nav:hover, .image-gallery-right-nav:hover{color: #fff !important;}
.image-gallery-left-nav{padding-right: 2px !important;}
.image-gallery-right-nav{padding-left: 2px !important;}
.banner-content h1{font-weight: 800;}
.banner-content p{font-weight: 400;}
@keyframes bg {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
  }
/* ---- about us  --------------- */
.aboutusboxMaine{width: 100%;padding: 4rem 0; }
.aboutUsBox{width:100%; margin: auto;  padding: 3.5rem 1rem;  border-radius: .325rem;  bottom: 0;
  z-index: 10;}
.aboutUsBox h3{font-size: var(--font34); font-weight: 700; line-height: normal; color: rgba(0, 0, 0, 1);}
.aboutUsBox p{color: #000000; font-size: var(--font18); text-align:justify;font-weight:500;}
/* #aboutus{
  background: linear-gradient(90deg, rgba(125, 124, 159, 0.37), rgba(236, 54, 15, 0.21) ), #fff 50% / cover no-repeat;
} */
#aboutus{background: url('./assets/images/aboutBG.svg') no-repeat center;      background-size: 200% 380%;}
.aboutUsBox .panelHead h3{text-align: left; margin-left: 0; margin-bottom: 3rem;}
.aboutUsBox .panelHead h3::before,.aboutUsBox .panelHead h3::after{width: 61px;}
.img-fluid{
  max-width: 50%;
    height: auto;
}
.img2{
  position:relative;
  left:-17%;
}
.rounded {
  border-top: 3px solid #303030;
  border-radius: 5px;
  width:8%;
}
.headlineabout{
  padding:7px 40px;
}
.roynd1{
  position:relative;
  left:26%;
  margin-bottom:2rem;
}
.aboutcontent {
margin-top:4rem;
}

/* feature */
.featureBoxMaine{padding-top: 20rem; padding-bottom: 4rem;}
.featureBoxMaine h3{font-size: 3rem; color: var(--secondery); font-weight: 800; margin-bottom: 3rem;}
.featureBoxMaine h3 span{font-size: var(--font32);}
.featureInnerBox{border: 2px solid var(--gray2); border-radius: 2.5rem; padding:1.5rem; padding-top: 3rem !important; height: 100%;}
.featureInnerBox h5{font-size: var(--font22);  color: var(--black); font-weight: 700;}
.featureInnerBox p{margin-bottom: 0; font-size: var(--font16); font-weight: 400; color: #777171;}

.Musebtn1{border-radius: 50px !important;    line-height: 2.875rem;
  min-height: 3rem; background:var(--white);color:var(--primary);font-size:var(--font18); border: 2px solid transparent !important; font-weight:600; box-shadow: 0 0 7px 1px #00000007; padding:0 0 !important; margin-right:1.25rem;  animation: bg 2s  ease infinite; background-size: 200% 200%; width: 11rem; text-align: center; text-decoration: none; transition: all .3s ease-in-out; justify-content: center;}
.Musebtn2{border-radius: 50px !important;  font-weight: 600;  line-height: 2.875rem;
  min-height: 3rem; border: 2px solid var(--white) !important;  color:var(--white); box-shadow: 0 0 7px 1px #00000007; font-size:var(--font18); padding:0 0 !important; width: 11rem; text-align: center;  animation: bg 2s  ease infinite; background-size: 200% 200%; text-decoration: none; transition: all .3s ease-in-out; justify-content: center;}
.Musebtn2:hover{background: var(--white); color: var(--primary);}
.Musebtn1:hover{background: var(--primary); color: var(--white);}
.Musebtn i{margin-left: 20px; font-size: var(--font20); color: #000;}
.round{
  border-top: 3px solid #303030;
  border-radius: 5px;
  width:15%;
  position: relative;
    left: 1%;
    margin-top:3rem;
}
.round2{
  border-top: 3px solid #303030;
  border-radius: 5px;
  width:15%;
  position: relative;
    left: 84%;
    margin-bottom:3rem;
}
.headlinetabs{
  font-size:var(--font34);
  padding:7px 0;
  font-weight:600;
}
.TabsBox{
  justify-content: center;
  display: flex;
}
.btnm1{
  display:flex;
  justify-content: center;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius:50px;
  padding:10px 10px;
}
.btnm1 .a{
  padding:20px;
}
.Musebtn3{border-radius: 50px !important; background:rgba(42, 146, 243, 1);color:#fff;font-size:16px; border: 0 !important;font-weight:600; box-shadow: 0 0 7px 1px #00000007; padding: 10px 60px !important; margin-right:30px; display: flex !important; align-items: center; animation: bg 2s  ease infinite; background-size: 200% 200%; width: fit-content; text-decoration: none;}
.Musebtn4{border-radius: 50px !important; border: 0 !important; color:#000; font-size:16px;font-weight:600; padding: 10px 60px !important; display: flex !important; align-items: center; animation: bg 2s  ease infinite; background-size: 200% 200%; width: fit-content; text-decoration: none;}
 .gbh{
    flex: 0 0 auto;
    width: 29.333333% !important;
 }
 .howterp{width: 100%; padding: 0 0; margin-top: 4rem;}
 .Featureboxs{
  box-shadow: rgba(0, 0, 0, 0.25) 0px 2px 50px;
  justify-content: center; background: var(--white);
  border-radius:1rem; overflow: hidden;
 position: relative;
  padding:1.875rem 1rem 3.875rem 1rem;
 }
 .Featureboxs .iconBox{width: 6.875rem; height: 6.875rem; aspect-ratio: 1/1; border-radius: 8.125rem; margin: auto;  display: grid; place-items: center;}
 .Featureboxs.register .iconBox{background-color: hsla(11, 73%, 71%, 0.35);}
 .Featureboxs.explore .iconBox{background-color: #D0E7FC;}
 .Featureboxs.buy .iconBox{background-color: hsla(323, 34%, 64%, 0.34);}
 .Featureboxs.physicalPrint .iconBox{background-color: hsla(135, 88%, 90%, 1);}

 .Featureboxs.register .iconBox img{width: 60px;}
 
 .Featureboxs h3{
  font-size:var(--font24);
  position: relative;     width: fit-content;
  margin: auto; margin-top: 1.5rem; 
  padding-bottom: 1rem;font-weight: 600;
 }
 .Featureboxs h3::before{content: ''; width: 50%; height: 3px; margin: auto; left: 0; right: 0; bottom: 0; position: absolute; }
 .Featureboxs p{ margin-top: 1rem; padding: 0 1.5rem;
  font-size:var(--font18);
  color:var(--black);
  text-align: center;
  margin-bottom: 2rem;font-weight: 500;
 }
 .Featureboxs.register h3{color: #E7836E;}
 .Featureboxs.register h3::before{background: #E7836E;}
 .Featureboxs.explore h3{color: #2A92F3;}
 .Featureboxs.explore h3::before{background: #2A92F3;}
 .Featureboxs.buy h3{color: #581343;}
 .Featureboxs.buy h3::before{background: #581343;}
 .Featureboxs.physicalPrint h3{color: #42C439;}
 .Featureboxs.physicalPrint h3::before{background: #42C439;}

 .Featureboxs .partnerDesign{position: absolute; bottom: 0; height: 6.5rem; left: 0; width: 100%; aspect-ratio: 1/.25;}
.Featureboxs.register .partnerDesign path{fill: #F8DBD4;}
.Featureboxs.explore .partnerDesign path{fill:#D0E7FC;}
.Featureboxs.buy .partnerDesign path{fill: #E6CDDC;}
.Featureboxs.physicalPrint .partnerDesign path{fill: #D0FCDB;}

/* ---list nft -------- */
.ListNFT{background-color: #F0EFF0; padding: 4rem 0 5rem; }
.ListNFT h3{font-size: 5rem; font-weight: 600; width: min(90%, 100%); margin-bottom: 2rem; color: var(--secondery);  line-height: 5rem; }
.ListNFT p{font-size: var(--font18); font-weight: 400; color: var(--black); }
.ListNFT h3 span{ background-image: url('./assets/images/bannerTextImg.png');  font-weight: 800;
  background-repeat: repeat; 
  -webkit-background-clip: text; 
  -webkit-text-fill-color: transparent; background-position: right; }
.listimg{width: 95%; position: relative; z-index: 0;}
.listimg::before{content: ''; width: 100%; height: 100%; position: absolute; top: 2rem; left: 2rem; background-color: #E0E0E0; z-index: -1;}
/* ------guideBox ---------- */
.guideBox{padding: 4rem 0; background-color: #FAF7FA;}
.guideBox h3{font-size: 3rem; font-weight: 700; color: var(--secondery); }
.guideInnBox{background-color: #FFF6F4; padding: 2rem;}

.guideInnBox h4{font-size: var(--font30); color: var(--secondery); font-weight: 700;}
.guideInnBox p{font-size: var(--font18); color: var(--black); font-weight: 400;}

.processBox{width: 100%;  background: url('./assets/images/processLine.svg') no-repeat center; background-size: contain; display: grid; grid-template-columns: repeat(4, 1fr); padding: 0 2.5rem;  gap: 2.5rem; min-height: 22rem;}
.processBox .boxin{width: 100%; margin: auto; aspect-ratio: 1/1; text-align: center; border-radius: 100%; padding: 0 1.875rem; display: flex; align-items: center; justify-content: center; flex-direction: column;}
.processBox .boxin h5{font-size: var(--font16); font-weight: 700;}
.processBox .boxin p{font-size: var(--font14); font-weight: 400; color: var(--white); margin: 0; line-height: 1.1rem;}

.processBox .boxin:nth-child(1){       translate: 0 3rem; background-color: #6C63FF;}
.processBox .boxin:nth-child(1) h5{color: #050148;}
.processBox .boxin:nth-child(2){       translate: .1rem -3rem; background-color: #EB9481;}
.processBox .boxin:nth-child(2) h5{color: #531407;}

.processBox .boxin:nth-child(3){     translate: .2rem 3rem; background-color: #C284AA;}
.processBox .boxin:nth-child(3) h5{color: #611042;}

.processBox .boxin:nth-child(4){     translate: .5rem -3rem; background-color: #6C63FF;}
.processBox .boxin:nth-child(4) h5{color: #050148 ;}

/* artworkBox box */
.ListNFT.artworkBox{background-color: #FAF7FA !important; padding-bottom: 2rem; }
.artworkBox h3{font-size: 3rem; font-weight: 700; color: var(--secondery); margin-bottom: 1.5rem; line-height: 3rem;}
.artmainebx {margin-top: 5rem;}
.artbx{background-color: #F5F5F5; padding: 0.625rem; height: calc(100% - 55px); transition: all .3s ease-in-out;}
.artbx:hover{height: calc(100% - 0px)}
.artbx h6{font-size: var(--font22); font-weight: 700; color: var(--secondery); padding-left: .625rem; margin-top: 1rem; translate: 0 -70px; opacity: 0; visibility: hidden;  transition: all .5s ease-in-out;}
.artbx:hover h6{translate: 0 0px; opacity: 1; visibility: visible;}

/* artist */
.meetArtist{background: url('../src/assets/images/artistBg.svg') no-repeat; position: relative; background-size: cover; width: 100%; z-index: 1; padding: 4rem 0 ;}
.meetArtist::before{content: ''; background: #1D1C21E5; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -1;}
.meetArtist h3{font-size: 3rem; font-weight: 700; color: var(--white); margin-bottom: 4rem; line-height: 3rem; text-align: center;}
.artistBox{border: 5px solid var(--white); padding: 2rem; text-align: center; height: 100%; transition: all .3s ease-in-out;}
.artistBox img{width: 100%; margin-bottom: 1rem;}
.artistBox h6{font-size: var(--font20); color: var(--white); font-weight: 700;}
.artistBox p{font-size: var(--font14); color: var(--white); font-weight: 400; margin: 0;}
.artistbsn:hover .artistBox{opacity: .5;}
.artistbsn .artistBox:hover{opacity: 1;}


/* ----------------------------blogs ------------------------------ */
.blogss{ padding: 4rem 0 ; transition: all .3s ease-in-out;}
.blogss h3{font-size: 3rem; font-weight: 700; color: var(--secondery); margin-bottom: 1rem; text-align: center;}
.blogBox{border-radius: 7px; width: 100%; height: 100%; position: relative; cursor: pointer;} 
.imgbox{position: relative ; width: 100%; height: 100%;  aspect-ratio: 1/0.6;}
.blogBox .imgbox img{width: 100%; height: 100%; border-radius: 7px; position: relative; z-index: 0;     object-fit: cover;
  object-position: top;}
.MaineBlogHome .blogBox .imgbox::before{transition: all .3s ease-in-out; content: ''; background-color:#000000a6; position: absolute; border-radius: 7px; top: 0; left: 0; width: 100%; height:  100%; z-index: 1; }
.MaineBlogHome:hover .blogBox .imgbox::before{background-color: #ffffffa6; transition: all .3s ease-in-out; border-radius: 7px;}
.MaineBlogHome:hover  .blogBox:hover .imgbox::before{background-color:#000000a6; transition: all .3s ease-in-out; }

.blogHomCont{position: absolute; z-index: 3; bottom: 0; left: 0; padding: 30px;}
.blogHomCont h4{font-size: var(--font28); font-weight: 600; text-align: left; color: var(--white); transition: all .3s ease-in-out; }
.blogHomCont p{font-size: var(--font14); color: var(--white); text-align: left; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden; transition: all .3s ease-in-out; }
.blogBox.smallBox .blogHomCont{padding: 15px;}
.blogBox.smallBox .blogHomCont h4{font-size: var(--font18);}
.MaineBlogHome:hover .blogBox h4{color: var(--black);}
.MaineBlogHome:hover .blogBox p{color: var(--black);}
.MaineBlogHome:hover .blogBox:hover h4{color: var(--white);}
.MaineBlogHome:hover .blogBox:hover p{color: var(--white);}

.blogsList{width: 100%; padding: 4rem 0 0; background: url('./assets/images/blogBg.svg') no-repeat center top;}
.blogbann{ width: 100%;background-size: cover; padding: 4rem 0;}
.blogbann h1{font-size: var(--font40); font-weight: 800; color: var(--black); }
.blogbann p{font-size:var(--font22); font-weight: 500; margin-bottom: 5px; color: var(--black);}
.blogbann p.dateBox {font-size: var(--font18);}
.blogbann p.dateBox img{filter: brightness(0);}

.maineContentBlog{padding-bottom: 4rem;}

.maineContentBlog img{margin-bottom: 3rem;}
.maineContentBlog h2{font-size: var(--font30); font-weight: 700; color: var(--black);}
.maineContentBlog h3{font-size: var(--font20); font-weight: 600; color: var(--black);}
.maineContentBlog p{font-size: var(--font16); color: var(--black);}


.blogcard{width: 100%; box-shadow: 0 3.8px 4px hwb(0 0% 100% / 0.25); transition: all .3s ease-in-out; background: var(--white);}
.blogcard:hover{box-shadow: 0 3.8px 4px hwb(0 0% 100% / 0.75); }
.blogcard .blogimg{width: 100%;aspect-ratio: 1/.5; overflow: hidden; }
.blogcard .blogimg img{width: 100%; object-fit: cover; object-position: top; height: 100%; margin-bottom: 0; transition: all .3s ease-in-out;}  
.blogcard:hover .blogimg img{transform: scale(1.2) rotate(5deg);}
.blogcard .cardContent{width: 100%; padding: 20px 15px;}
.blogcard .cardContent h3{font-size: var(--font20); color: var(--black); font-weight: 700; display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 2; overflow: hidden;}
.blogcard .cardContent p{color: var(--black); display: -webkit-box; -webkit-box-orient: vertical; -webkit-line-clamp: 3; overflow: hidden; }
.blogcard .datebox{border-top: 1px solid var(--black); padding-top: 15px; padding-bottom: 0px;}
.blogcard .datebox img{margin-bottom: 0; filter: brightness(0);}

.relatedBlogs{background: #F4F4FF; width: 100%; padding: 3rem 0; margin-top: 3rem;}
.realetdhead{display: flex; align-items: center; justify-content: space-between; margin-bottom: 1rem;}
.realetdhead h4{font-size: var(--font30);font-weight: 700; color: var(--black);}
.realetdhead h5{font-size: var(--font18);font-weight: 600; color: var(--black);}
.loaderBoxMiane{    display: grid;
  place-items: center;
  width: 100%;
  height: 100vh;
  background: #00000091;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;}

.blogData *{font-family: inherit !important;}


.panelHead h3{font-size: var(--font40);font-weight: 700; color: var(--darkGray);  text-align: center; margin-bottom: 3.5rem; position: relative;     width: fit-content; margin: auto; line-height: 40px;}
.panelHead h3::before{content: ''; width: 4.6875rem; border-top: 0.2rem solid #303030; position: absolute; top: -.125rem; right: 100%; }
.panelHead h3::after{content: ''; width: 4.6875rem; border-top: 0.2rem solid #303030; position: absolute; bottom: 0rem; left: 100%; }

.panelHead p{font-size: var(--font20);font-weight: 500; color: var(--black);  text-align: center;}


/* ----------------------tabDesign --------------------- */
.tabs{width: fit-content; background: var(--white); padding: 0.8125rem; border-radius: 3.125rem; gap: 0.625rem; display:inline-flex; align-items: center; justify-content: center; margin: auto; margin-top: 3rem;}
.tabs button{background: none; display: grid; font-weight: 500; place-items: center; outline: none; border: 0; line-height: 3.25rem; min-width: 15.625rem; border-radius: 3.125rem; font-size: var(--font18); color: var(--black);  transition: all .3s ease-in-out;}

.tabs button:hover, .tabs button.active{background: var(--bluecolor); box-shadow: 0 0.125rem 10.6px hsla(0, 0%, 0%, 0.25); color: var(--white);}
.LearnMoreBtn{background: var(--bluecolor); border: 0.125rem solid var(--white);font-weight: 600; font-size: var(--font18); color: var(--white);  outline: none; margin-top: 4rem; border-radius: 3.125rem; height: 3.5rem; padding: 0 3.125rem;  transition: all .3s ease-in-out;}
.LearnMoreBtn:hover{background: #1371c9;}
.LearnMoreBtn svg{margin-left: 0.625rem;}
/* -----------------------how terpnash works ----------------------- */
.howterpnashWorks{width: 100%; padding: 4rem 0 ; position: relative;}
.howterpnashWorks::before{content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: linear-gradient(180deg, #1877F200, #EC360F00, #EC360F5C);opacity: .40; z-index: -1;}
.howterpnashWorks::after{content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%;  background: url('./assets/images/jpg/howTerpnashWorks.jpg') no-repeat ;     background-position: 100% 100%; background-size:cover ;  opacity: .25; z-index: -2;}

.Featureboxs button{position: absolute; z-index: 1; left: 0; right: 0; margin: auto; width: fit-content;     bottom: 1.5rem;
  height: 3rem; aspect-ratio: 1/1; padding: 0; display: grid; place-items: center;}
.Featureboxs button svg{ margin-left: 0;}
.Featureboxs.physicalPrint button{background-color: #42C439;}

/* --------------------- tranding Artwork ----------------- */
.trandingArtwork{width: 100%; position: relative; padding: 4rem 0; z-index: 0;} 
.trandingArtwork::before{content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: url('./assets/images/trandingartBg.svg') no-repeat center; z-index: -1;}

.trandingArt{background: var(--white); padding: 0.9375rem; border-radius: 0.9375rem; box-shadow: 0 2px 50px rgb(0 0 0 / 5%);}
.trandingArt .imgBox{width: 100%; position: relative;}
.trandingArt .imgBox img{width: 100%; border-radius: 1.4375rem; aspect-ratio: 1/1;}
.artistNameBox{position: absolute; top: 0; left: 0; padding: 10px; background: url('./assets/images/trandiNftdesign.svg') no-repeat; height: 5.6875rem; width: 18.25rem; background-size: contain; display: flex; gap: 10px; background-position: -1px 0;}
.artistNameBox img{width: 2.1875rem !important; height: 2.1875rem; }
.artistNameBox h5{margin-bottom: 0; line-height: 35px; font-size: var(--font18); color: var(--black);} 
.trandingArt .contentBox {margin-top: 1.25rem;}
.trandingArt .contentBox h3{font-size: var(--font20); margin: 0; word-break: break-all;
  color: var(--black); line-clamp: 1;   display: -webkit-box; margin-bottom: .5rem;
  -webkit-box-orient: vertical; overflow: hidden;   -webkit-line-clamp:1;
  }

.trandingArt .contentBox .artPriceBox{display: flex; align-items: center; justify-content: space-between;}
.trandingArt .contentBox .artPriceBox .artRate {display: flex; align-items: center; gap: 10px;}
.trandingArt .contentBox .artPriceBox .artRate h4{font-size: var(--font24);
  color: #7D7C9F; margin-bottom: 0; 
  }

.artPriceBox ul{list-style: none; padding: 0; margin: 0; display: flex; align-items: center; gap: 20px; }
.artPriceBox ul li{ display: flex; align-items: center; gap: .325rem;}
.artPriceBox ul li h5{font-size: var(--font18);
  color: var(--black); margin-bottom: 0; margin-top: 4px;
  }
  .artPriceBox ul li img{width:1.5rem !important;}
  .viewAll{position: relative;}
  .viewAll a{position: absolute; text-decoration: none; color: var(--black); display: flex; align-items: center; gap: 1rem; right:0rem; top: 0; bottom: 0; font-size: var(--font20);font-weight:500;background: none; outline: none; border:none; }
  .viewAll a svg{transition: all .3s ease-in-out; width: 2.5rem;}
  .viewAll a:hover svg{transform: translateX(.5rem);}
/* -------------------------- why terpnesh -------------------- */


.whyTerpneshMain{width:100%; padding: 4rem 0; position: relative; z-index:0 ;} 
.whyTerpneshMain::before{content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: linear-gradient(0,  hsla(11, 88%, 49%, 0.90), hsla(214, 89%, 52%, 0));opacity: .40; z-index: -1;}
.whyTerpneshMain::after{content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%;  background: url('./assets/images/whyTerpnash.jpg') no-repeat ;     background-position: 100% 100%; background-size:cover ;  opacity: .20; z-index: -2;}

.whyTerpneshMain .panelHead h3 {margin-left: 4rem;}
.whyTerpneshMain .panelHead p {text-align: left; margin-top:2rem !important; margin-left: 4rem;     padding-right: 18.125rem;}
.paddleft6rem{padding-left: 4rem;}
.whyinnBox{width: 100%; display: flex; align-items: baseline; gap: 20px; margin-top: 2rem; }
.whyinnBox .imgBox{width: 35px;}
.whyinnBox .imgBox img{width: 100%;}
.whyinnBox .contentBox{flex: 1; width: calc(100% - 2.1875rem); position: relative;}
.whyinnBox .contentBox h3{font-size: var(--font24); color: var(--black);font-weight: 600;}
.whyinnBox .contentBox p{font-size: var(--font18); color: var(--black); padding-right: 0 ;}
.topleftdesign{position: absolute; top: 0; right: 0; z-index: -3; width: 41.3125rem; height: 36.5rem;}
.whyinnBox .contentBox::before{content: ''; position: absolute; bottom: 0; left: 0; width: 5.9375rem; border-top: 0.125rem solid #000;}
/* ------------------------- featured Artist ------------------ */
.featuredArtistMain{width:100%;  padding: 4rem 0; position: relative; z-index:0 ;} 
.featuredArtistMain::before{content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: linear-gradient(50deg, #ec360f24 70%, hwb(213deg 56% 17% / 85%)); opacity: .40; z-index: -1;}
.featuredArtistMain::after{content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%;  background: url('./assets/images/featuredArtistbg.jpg') no-repeat; background-size:cover ;  opacity: .20; z-index: -2;}
.artistcard h4{display: flex; align-items: center; gap: 10px; margin-bottom: 0; margin-top: 1rem !important;}
.artistcard h4 img{aspect-ratio: 1/1;}
.artistcard a{background: none; outline: none;  border: none; margin-top: 1.25rem; transition: all .3s ease-in-out;}
.artistcard a:hover{transform: translateX(10px);}
.artistcard a img{width: 2.5rem !important;}

.margintopfeature{margin-top: 2rem;}
.artist img{width: inherit !important;   }

.artistImgBox{display: flex; align-items: center; gap: 10px;}
.artistImgBox .leftImg{height: 19.5rem; background: red; flex: 1; border-radius: 9px; overflow: hidden;}
.artistImgBox .RightImg{height: 19.5rem; display: flex; flex-direction: column;  flex: 1; align-items: center; justify-content: center; gap: 10px;}
.artistImgBox .RightImg .rtop1{background-color: green; width: 100%; height: 6.5rem; border-radius: 9px; overflow: hidden;}
.artistImgBox .RightImg .rtop2{background-color: rgb(60, 224, 60); width: 100%; height: 6.5rem; border-radius: 9px; overflow: hidden;}
.artistImgBox .RightImg .rtop3{background-color: rgb(14, 29, 238); width: 100%; height: 6.5rem; border-radius: 9px; overflow: hidden;}

.imageGrid{display: grid; grid-template-columns: repeat(2, 1fr); gap: 10px; height: 21rem;}
.imageGrid .imgBOx{width: 100%;  overflow: hidden; border-radius: 9px; height: 6.5rem; background: #ccc; display: grid; place-items: center;}
.imageGrid .imgBOx img{height: 100%; object-fit: cover; border-radius: 9px;}
.imageGrid .image1 {
  height: 100% ;
  grid-row-start: 1;
  grid-row-end: 4;
}
.imageGrid .imgBOx.img1 img{width: 50px; height: 50px; object-fit: contain;}
.imageGrid .imgBOx.img2 img{width: 50px; height: 50px; object-fit: contain;}
.imageGrid .imgBOx.img3 img{width: 50px; height: 50px; object-fit: contain;}
.imageGrid .imgBOx.img4 img{width: 50px; height: 50px; object-fit: contain;}

/* ------------------------ Learning resource Box ------------- */
.lerningresBoxMaine{width: 100%; background: url('./assets/images/jpg/learningResourcebg.jpg') no-repeat center; background-size:cover; padding: 4rem 0;} 
.learningCart{width: 100%; background: var(--white); padding: 17px; border-radius: 15px;}
.learningCart img{border-radius: 15px ; width: 100%;   aspect-ratio: 1 / .7; object-fit: cover;}
.learningCart h4{font-size: var(--font20);font-weight:600; color: var(--black);  margin-top: 1.25rem; text-transform: capitalize;}
.learningCart p{font-size: var(--font18); color: var(--black); margin-top: 12px;}
.learningCart a{font-size: var(--font16); color: var(--black); }


.videoBoxMaine{position: fixed; top: 0; left: 0; width: 100%; height: 100vh; background: #000000c5; backdrop-filter: blur(10px); z-index: 10; visibility: hidden; opacity: 0; transition: all .3s ease-in-out;}
.videoinBox{width: 100%; height: 100%; position: relative; display: grid; place-items: center;}
.closeBtn{position: absolute; top: 2rem; right: 2rem; width: 2.5rem; height: 2.5rem; background: #fff; border-radius: 100%; display: grid; place-items: center;}
.inerVideoBox{width: 50%; background: #fff;     padding: 10px 10px 3px; border-radius: 5px;}
.inerVideoBox iframe{width: 100%; aspect-ratio: 1/.6;}
.artistcard img{aspect-ratio: inherit;}
.margintoplearning{margin-top: 4rem;}
.videoBoxMaine.active{visibility: visible; opacity: 1;}

.videoBox{height: 451px; position: relative; overflow: hidden; cursor: pointer;}
.videooverlay{position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: #00000046; transition: all .3s ease-in-out; display: grid; place-items: center;}
.videooverlay svg{width: 95px; height: 95px;}
.videoBox img{height: 100%; aspect-ratio: inherit; object-fit: cover;}
.videoBox:hover .videooverlay{background: #0000009a;}


.newAccordian .accordion-item{margin-bottom: 1rem; border-radius: 5px !important;}
.newAccordian .accordion-item .accordion-button{border-radius: 5px !important; font-size: var(--font18);padding-top: 1.1rem; }
.newAccordian .accordion-item .accordion-button:focus{box-shadow: none;}
.accordion-button:not(.collapsed){background-color: var(--primary); color: var(--white);}
.accordion-button:not(.collapsed)::after{filter: brightness(100);}
/* ---------------------------referral --------------------- */
.referralMain{background: linear-gradient(56deg, #dbdddf, #e9ded8);  position: relative; display: flex;overflow: hidden;}
/* .referralMain::before{content: ''; background: url('./assets/images/discountLeft.svg') no-repeat; width: 410px; height: 600px; position: absolute; left: 0; top: 0;} */
/* .referralMain::after{content: ''; background: url('./assets/images/discountRight.svg') no-repeat; width: 736px; height: 600px; position: absolute; right: 0; top: 0;} */
.referralMain .contentBox{background: linear-gradient(#F5F2EB, #E3D5CA); min-width: 40rem;  max-height: 37.75rem; padding: 5.625rem 3.4375rem; padding-bottom: 3rem;}
.contentBox h3{font-size: var(--font32);font-weight:600; color: var(--darkGray);  }
.contentBox h4{font-size: var(--font48);font-weight:600; color: var(--darkGray);  margin-bottom: 3rem; }
.contentBox p{font-size: var(--font24); color: var(--secondery);  padding-right: 5rem; }

.referralMain .leftimg img,.referralMain .rightBox img{height: 100%; max-height: 37.75rem;}
.referralMain .rightBox img{box-shadow: -7px 3px 23.100000381469727px 0px #0000001A;}

/* -------------------------- partner ---------------------- */
.partner{width: 100%; padding: 4rem 0; position: relative;}
.partner::before{content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: linear-gradient(-90deg, #EC360F, #A1BFDE) ; opacity: .46; z-index: -2;}
.partner h3{margin-bottom: 3rem;
} 
/* :is(h3:before, h3::after) .partner{display: none;} */
.partnerBox{width: 100%; height: 100%; overflow: hidden; z-index: 0; background: var(--white); border: 0rem  solid #DCC7FF; border-radius: 1.0625rem; position: relative; padding: 1.875rem;}
.partnerBox img{height:2.5rem; width: 9rem;}
.partnerBox p{font-size: var(--font14);font-weight: 500; line-height: 2rem; margin-top: 1rem;}
.partnerBox a{ min-height: 2.8125rem; text-decoration: none; letter-spacing: .05rem;font-weight:500; color: var(--white); line-height: 2.0625rem;  margin-bottom: 1rem; font-size: var(--font14); display: grid; place-items: center; width: 9.375rem; border-radius: 1.875rem; outline: none; border: none;}
.partnerBox .partnerDesign{position: absolute; z-index: -1; height: 6rem; border-radius:0 0.9375rem ; bottom: 0; left: 0; width: 101%; aspect-ratio: 1/.25;}
.partnerBox.muse .partnerDesign path{fill: hsla(209, 89%, 56%, 0.56);}
.partnerBox.prima .partnerDesign path{fill: hsla(11, 88%, 49%, 0.3)}
.partnerBox.fineart .partnerDesign path{fill: hsla(318, 64%, 21%, 0.3);}
.partnerBox.muse a{background: #2A92F3;}
.partnerBox.prima a{background: #E7836E;}
.partnerBox.fineart a{background: #581343;}



/* -------------------------- invite ----------------------- */
.starss{position:absolute; top:1rem; right:27rem
}
.right-star{position:absolute; top:-14rem; left:2rem; width:2rem;box-shadow: none !important }
.white-strr{position:absolute; left:8rem;top:15rem}
.black-strs{position:absolute; left:7rem; top:0rem}
.row-str{position:absolute; top:16rem; left:10rem; width:3.5rem}
.black-str{position:absolute; right:27rem; top:4rem}
.btm-starsss{position:absolute; bottom:20rem; left:6rem}
.starsss{position:absolute; top:6rem; right:35rem}
.black-stars{position:absolute;top:22rem; left:20rem}
.black-starss{position:absolute;bottom:3rem; left:35rem}
.black-starsss{position:absolute;bottom:7rem; right:5rem}

.trandingArtwork, .whyTerpneshMain , .rightBox, .featuredArtistMain, .lerningresBoxMaine , .partner{position:relative}
.stayinthe{width: 100%; background-color: #fff; position: relative; z-index: 0; overflow: hidden;} 
.stayinthe::before{content: ''; position: absolute; z-index: -1; width: 100%; height: 100%; background:url('./assets/images/stayinloopimg.svg') no-repeat center; background-size: 3400px;} 

.stayimg{width: 100%; height: auto;scroll-behavior: smooth ;overscroll-behavior: auto;}
#blogtop{scroll-behavior: smooth ;overscroll-behavior: auto;}
/* body{scroll-behavior: smooth !important;} */

.footeMain{width: 100%; position: relative; z-index: 0;}
.footeMain::before{content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%;  background: url('./assets/images/footerImg.jpg') no-repeat center;     background-size: cover; z-index: -3;}
.footerInner{width: 100%; height: 100%; position: relative;}
.footerInner::before{content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; z-index: -2;}
.footerInner::after{content: ''; position: absolute; top: 0; left: 0; width: 100%; height: 100%; background: #09082A; opacity: .9; z-index: -1;}
.stayintext{padding-left: 4.375rem;}
.stayintext h4{font-size: 3rem;  color: var(--darkGrays);font-weight: 700;}
.stayintext p{font-size: var(--font18);  color: var(--black);font-weight: 500;}
.subscribeBox{display:flex; align-items: center; gap: 1.25rem;}
.subscribeBox input{flex: 1; background: var(--white);font-weight:600;  height: 3.5625rem; line-height: normal; padding: 1.1875rem 1.25rem 1rem; color:var(--secondery) ; border: 2px solid transparent; border-radius: 1.875rem;outline: 0; transition: all .3s ease-in-out;}
.subscribeBox input:focus{border-color: var(--bluecolor);}
.subscribeBox button{flex: 1; background: var(--white);font-weight:600; display: grid; flex: 0 0 18.75rem; place-items: center; line-height:3.315rem; outline: none;padding-top: 0.19rem; border: 0; border-radius: 1.875rem; background: var(--bluecolor); color:var(--white); font-size:var(--font18) ; }

.bnannerNewimg{width: 100%; height: 100%;}
.bnannerNewimg img{
width: 100%;
}


.inviteMain{width: 100%; padding: 4rem 0;}
.inviteMain h3{font-size: 3rem; font-weight: 700; color: var(--secondery); margin-bottom: 1rem;}
.inviteMain h4{font-size: var(--font18); font-weight: 600; color: var(--secondery); margin-bottom: 1rem; }
.inviteimg{outline: 5px solid #ffffff;  transition: all .3s ease-in-out; }
.inviteimg:hover{  outline-offset: -30px;}
/* -------------footer */
.footer{position: relative; background: none; width: 100%; z-index: 1; padding: 3rem 0;}
.footer::before{content: '';  z-index: -1; position: absolute; top: 0; left: 0; width: 100%; height: 100%;  opacity: .25;}
.footer .logoBox{color: var(--white);}
.footer .logoBox img{width: 250px;}
.footer p{color: var(--white); font-size: var(--font18);  padding-right: 5rem;}
.footer h6{font-size: var(--font24);font-weight:700;  color: var(--white); padding: 1.25rem 0; margin-bottom: 1rem;}
.footer ul{list-style: none; padding: 0; margin: 0;}
.footer ul li{margin-bottom: .75rem;}
.footer ul li a,.footer ul li {color: var(--white);  font-size: var(--font18);  display: flex; align-items: flex-start; flex-wrap: wrap; gap: .625rem;}
.footer ul li a:hover{color: var(--bluecolor);}
.footer ul li img{margin-top: 0.125rem;}

ul.social_icon li {
  list-style-type: none;
}
ul.social_icon {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 13px;
}
ul.links_here {
  padding: 0;
  margin: 0;
}
ul.links_here li {
  list-style-type: none; margin-bottom: 7px;
}
ul.links_here li a,ul.links_here li {
 
  font-weight: 400;
 
  font-size: var(--font14);
  color: var(--gray2);
  text-decoration: none;
}
ul.links_here li i{width: 20px; text-align: center;}
ul.links_here li a:hover {
  color: var(--btn-yellow);
}

.socialIconn{width: 2.5rem; height: 2.5rem; display: block; transition: all .7s;}
.facebookIcon{background: url('./assets/images/facebook.svg') no-repeat; background-position: 0 0; background-size:2.5rem}
.facebookIcon:hover{background: url('./assets/images/facebook.svg') no-repeat; background-position: 0 -3.25rem;  background-size:2.5rem}

.twitterIcon{background: url('./assets/images/x.svg') no-repeat;  background-position: 0 0; background-size:2.5rem}
.twitterIcon:hover{background: url('./assets/images/x.svg') no-repeat; background-position: 0 -3.25rem;  background-size:2.5rem}

.LinkedinIcon{background: url('./assets/images/linkedin.svg') no-repeat;  background-position: 0 0; background-size:2.5rem}
.LinkedinIcon:hover{background: url('./assets/images/linkedin.svg') no-repeat; background-position: 0 -3.25rem;  background-size:2.5rem}


.youtubeIcon{background: url('./assets/images/youtube.svg') no-repeat;  background-position: 0 0; background-size:2.5rem}
.youtubeIcon:hover{background: url('./assets/images/youtube.svg') no-repeat; background-position: 0 -3.25rem;  background-size:2.5rem}

.instagramIcon{background: url('./assets/images/instagram.svg') no-repeat; background-position: 0 0; background-size:2.5rem }
.instagramIcon:hover{background: url('./assets/images/instagram.svg') no-repeat; background-position: 0 -3.25rem;  background-size:2.5rem}

.pintrestIcon{background: url('./assets/images/pintrest.svg') no-repeat; background-position: 0 0; background-size:2.5rem }
.pintrestIcon:hover{background: url('./assets/images/pintrest.svg') no-repeat; background-position: 0 -3.25rem;  background-size:2.5rem}

.discordIcon{background: url('./assets/images/discord.svg') no-repeat; background-position: 0 0; background-size:2.5rem }
.discordIcon:hover{background: url('./assets/images/discord.svg') no-repeat; background-position: 0 -3.25rem;  background-size:2.5rem}

.telegramIcon{background: url('./assets/images/telegramIcon.svg') no-repeat; background-position: 0 0; background-size:2.5rem }
.telegramIcon:hover{background: url('./assets/images/telegramIcon.svg') no-repeat; background-position: 0 -3.25rem;  background-size:2.5rem}


.copyright{background: rgba(255, 255, 255, 0.30); padding: 1.5rem 0;
  backdrop-filter: blur(4px); text-align: center; color: var(--white); font-size: var(--font16);font-weight: 400; }


.backto{background-color: var(--bluecolor); width: 35px; cursor: pointer; height:35px; border-radius:.325rem; position: fixed; bottom: 1rem; right: 2rem; padding: 5px; opacity: 0; visibility: hidden; z-index: 10;} 
.backto img{width: 100%; filter: brightness(10);}
.backto.active{bottom: 2rem; opacity: 1; visibility: visible;}
.guidebtns{display: flex; align-items: center; flex-wrap: wrap; gap: 1.25rem;}
.guidebtns button{width: 100%; }
.pe5des{padding-right: 2.5rem;}

.menunew{width: 100%; display: flex; align-items: center; justify-content: end;}
.navbarNew{display: flex; align-items: center; list-style: none; padding: 0;}

@media (min-width: 1400px){
  .container{max-width: 90% !important;}
}
@media (max-width: 1600px){

.learningCart h4{font-size: var(--font20);}
.sliderBox {width: 78%;}
.sliderBox .image-gallery-slide img{height: 48vh;}
.partnerBox p{letter-spacing: 0;}
.partnerBox{padding: 1.5rem;}
}
.gap3{gap: 3rem;}
@media (max-width:567px){
  html{ font-size: calc(1.2vh + 1.2vw);}
  body{overflow: hidden; overflow-y: auto;}
  .bannertext h3 {font-size: 3.2rem; letter-spacing: 5px;}
  .bannertext p{width: 100%;}
  .bannerBox{height: auto; padding-bottom: 0rem;}
  .featureBoxMaine{padding-top: 4rem;}
.guidebtns button{width: auto; margin-bottom: 1.25rem;}
.processBox{background: none; grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  padding: 0;}
.processBox .boxin{translate: inherit !important; border-radius: .5rem; padding: .5rem; height: 100%;}
.listimg{width: 90%; margin:1rem auto;}
.listimg::before{top: .5rem; left: .5rem;}
.artbx{height: 100%;}
.artbx h6{visibility: visible; opacity: 1; translate: inherit !important;}
.pe5des{padding-right:0;}
.mobilebtn{margin-bottom: 1rem; width: fit-content;}
.aboutUsBox{position: relative; translate: inherit; margin-bottom: 4rem;}
.bannerBox{padding-bottom: 4.6rem;}
.menunew{position: absolute; top: 100%; right: 0; background: linear-gradient(90deg, #EC360F, #87A5CD); flex-direction: column;     width: fit-content; padding:0 15px; display: none;}
.navbarNew{flex-direction: column; align-items: start;}
.menunew.active{display: block;}


/* -----------------new design ------------- */
.tabs{width: 100%;}
.tabs button{width: auto; padding: 0 2rem; min-width:auto}
.panelHead h3{font-size: var(--font24);}
.aboutUsBox .panelHead h3{margin-left: auto;}
.learningCart{margin-bottom: 1rem;}
.viewAll a{top: -2rem; bottom: auto; right: 1rem;}
.partnerBox {margin-bottom: 1rem; height: auto;}
.partnerBox .partnerDesign{width: 111%; height: 7rem;}
.stayintext{padding-left: 0; padding-bottom: 3rem;}
.stayintext h4{font-size: 2.25rem;}
.subscribeBox{flex-wrap: wrap;}
.whyTerpneshMain .panelHead p{padding-right: 0; padding-left: 0;}
.whyTerpneshMain .panelHead h3{margin-left: auto;}
.paddleft6rem{padding-left: 0;}
.gap3{gap: 1rem;}
.howterp{margin-top: 0;}
.trandingArt{margin-bottom: 1rem;}
.tabs{margin-bottom: 3rem;}
ul.social_icon{gap: 11px;}

}
@media only screen and  ((min-width:567px) and (max-width:991px)){
  .container{max-width: 100%;}
  html{ font-size: calc(1.2vh + 1.2vw);}
  body{overflow: hidden; overflow-y: auto;}
  .bannertext h3 {font-size: 3.2rem; letter-spacing: 5px;}
  .bannertext p{width: 100%;}
 /*.bannerBox{padding-bottom: 1rem;}
  .bannerBox{height: auto; padding-bottom: 4rem;}*/
  .featureBoxMaine{padding-top: 4rem;}
.guidebtns button{width: auto; margin-bottom: 1.25rem;}
.processBox{background: none; grid-template-columns: repeat(2, 1fr);
  gap: 0.5rem;
  padding: 0;}
.processBox .boxin{translate: inherit !important; border-radius: .5rem; padding: .5rem; height: 100%;}
.listimg{width: 90%; margin:1rem auto;}
.listimg::before{top: .5rem; left: .5rem;}
.artbx{height: 100%;}
.artbx h6{visibility: visible; opacity: 1; translate: inherit !important;}
.pe5des{padding-right:0;}
.mobilebtn{margin-bottom: 1rem; width: fit-content;}
.aboutUsBox{position: relative; translate: inherit; margin-bottom: 4rem; width: 100%;}

.processBox .boxin h5{font-size: var(--font18);}
.processBox .boxin p{font-size: var(--font16); line-height: 1.5rem;}
.menunew{position: absolute; top: 100%; right: 0; background-color: #fff; flex-direction: column;     width: fit-content; padding:0 15px;  display: none;}
.navbarNew{flex-direction: column; align-items: start;}
.menunew.active{display: block;}

}




@media only screen and  ((min-width:992px) and (max-width:1024px)){
  .processBox{background: none; grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
    padding: 1rem;}
  .processBox .boxin{translate: inherit !important; border-radius: .5rem; padding: .5rem; }
  .processBox .boxin h5 {
    font-size: var(--font22);
}
  .processBox .boxin p{font-size: var(--font18); line-height: 1.6rem;}
}

@media only screen and  ((min-width:1025px) and (max-width:1600px)){
  .processBox{background: none; grid-template-columns: repeat(4, 1fr);
    gap: 2rem;
    padding: 1rem;}
  .processBox .boxin{translate: inherit !important; border-radius: .5rem; padding: .5rem; }
  .processBox .boxin h5 {
    font-size: var(--font20);
}
  .processBox .boxin p{font-size: var(--font16); line-height: 1.6rem;}
}
@media only screen and (min-width:312px) and (max-width:475px){
  .Musebtn1{
    margin-bottom:2rem;
  }
  .Musebtn2{
    margin-bottom:2rem;
  }
  .Featureboxs p {
    margin-top: 1rem;
    padding: 0 1.5rem 1rem 1.5rem;}
    .Featureboxs .partnerDesign{
      height:7rem;
    }
    .tabs button {
      width: auto;
    padding: 0 2.9rem !important;
    min-width: auto;
    }
    .lerningresBoxMaine .tabs{
      margin-bottom:0rem;
    }
    .lerningresBoxMaine .tabs button{
      padding:0 1.9rem !important;
    }
.artistNameBox {
  position: absolute;
  top: 0;
  left: 0;
  padding: 5px;
}
.topleftdesign {
  width: 13.3125rem !important;
  height: 11.5rem !important;
}
.referralMain .leftimg img{
  width: -webkit-fill-available;
}
.referralMain{
  display:block;
}
.referralMain .contentBox {
  background: linear-gradient(#F5F2EB, #E3D5CA);
   min-width: 2rem !important;
  max-height: 36.75rem;
  padding: 3.625rem 1rem;
  padding-bottom: 2rem;
}
.contentBox p {
  font-size: var(--font18);
}
 .referralMain .contentBox p{
  padding-right:0rem;
 }
 .partnerBox p{
  margin-bottom:2rem;
 }
 .partnerBox .partnerDesign {
  width: 100%;
  height: 6.5rem !important;
}
.sliderBoxNew {
  padding: 0.3rem;
}
.inerVideoBox {
  width: 90%;
}
.closeBtn{
  width:3rem;
} 
}
@media (max-width:1024px){
  .sliderBoxNew{
    padding:0.3rem;
  }
  .Featureboxs .partnerDesign{
    height:5rem;
  }
  .artistNameBox {
    padding: 2px;
    height: 5.6875rem;
    width: 13.25rem;
  }
  .artistNameBox h5 {
    margin-bottom: 0;
    line-height: 26px;
  }
  .topleftdesign {
    width: 23.3125rem;
    height: 20.5rem;
  }
  .inerVideoBox {
    width: 80%;
  }
  .referralMain .leftimg{
    min-width:19rem;
  }
  .referralMain .contentBox {
    min-width: 33rem;
    max-height: 37.75rem;
    padding: 3.625rem 3.4375rem
  }
  .partnerBox .partnerDesign {
    height:6rem;
    width:104%;
  }
}
@media (max-width:540px){
  .btnm {
    margin-bottom:0.5rem;
  }
  .lerningresBoxMaine .tabs{
    margin-bottom:0rem;
  }
  .tabs button {
    padding: 0 3.9rem;
  }
  .Featureboxs p{
    margin-bottom: 3.5rem !important;
  }
  .Featureboxs .partnerDesign {
    height: 7.4rem;
}
.Featureboxs {
  margin-bottom: 1.5rem;
}
.artistNameBox {
  padding: 9px;
  height: 5.6875rem;
  width: 17.25rem;
}
.artistNameBox h5 {
  margin-bottom: 0;
  line-height: 33px !important;
}
.viewAll a {
  top: -2rem !important;
  bottom: auto;
  right: 1rem;
}
.referralMain{
  display:block;
} 
.referralMain .leftimg img {
  width: -webkit-fill-available;
} 
.referralMain .contentBox {
  padding: 3.625rem 1.4375rem;
}
.partnerBox .partnerDesign {
  height: 9rem ;
  width: 102%;
}
.partnerBox a {
  margin-bottom:2rem;
}
}
@media only screen and (min-width:768px) and (max-width:992px){
  .Musebtn1{
    margin-bottom:1rem;
  }
  .Musebtn2{
    margin-bottom:1rem;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 100%;
}
.whyTerpneshMain .panelHead p {
  text-align: center;
  margin-top: 2rem !important;
  margin-left: auto;
  padding-right: 0.125rem;
}
.whyTerpneshMain .panelHead h3 {
  margin-left: auto;
}
.aboutUsBox .panelHead h3 {
  text-align:center;
  margin-left: auto;
}
.panelHead h3::after {
  content: '';
  width: 4.6875rem;
  border-top: 0.125rem solid #303030;
  position: absolute;
  bottom: -0.725rem;
  left: 100%;
}
.panelHead h3::before {
  content: '';
  width: 4.6875rem;
  border-top: 0.125rem solid #303030;
  position: absolute;
  top: -.725rem;
  right: 100%;
}
.aboutUsBox {
  margin-bottom: 0rem;
}
.howterpnashWorks .tabs button {
  min-width: 11.625rem;
}
.lerningresBoxMaine .tabs button {
  min-width: 8.625rem;
}
.Featureboxs p {
  margin-bottom:3rem;
}
.Featureboxs .partnerDesign {
  height: 7.5rem;
}
.gap3 {
  gap: 0.1rem;
}
.viewAll a {
  top:-10rem;
}
.trandingArt {
  margin-bottom:2rem;
}
.artistNameBox h5 {
  line-height: 57px;
}
.artistNameBox {
  padding: 10px;
  width: 21.25rem;
}
.topleftdesign {
  width: 17.3125rem;
  height: 15.5rem;
}
.learningCart {
  margin-bottom: 2rem;
}
.partnerBox a {
  margin-bottom: 3rem;
}
.partnerBox .partnerDesign {
  height: 8rem;
  width: 100%;
}
.partner .col-lg-3{
  margin-bottom: 2rem;
}
.subscribeBox button {
  flex: 0 0 15.75rem;
}
.subscribeBox {
  margin-bottom: 2rem;
}
.stayintext {
  padding-left: 0.375rem;
}
.referralMain{
  display: block;
}
.referralMain .leftimg img{
  width: -webkit-fill-available;
}
.referralMain .rightBox img {
  width: -webkit-fill-available;
}
.referralMain .contentBox {
  min-width: 30rem;
  padding: 3.625rem 1.4375rem;
}
}
@media (max-width:768px){
  .partnerBox .partnerDesign {
    height: 9rem;
    width: 100%;
}
}
@media (max-width:280px){
    .tabs button {
      padding: 0 2.9rem;
  }
  .lerningresBoxMaine .tabs button{
    padding:0 1.9rem;
  }
  .topleftdesign {
    width: 17.3125rem;
    height: 15.5rem;
}
.referralMain .contentBox {
  min-width: 28rem;
  max-height: 40.75rem;
  padding: 3.625rem 1.4375rem;
}
.partnerBox .partnerDesign {
  height: 6rem;
  width: 100%;
}
}

.EmailVerifyBoxModel .modal-content{border-radius: 5px; padding:30px 15px;}
.EmailVerifyBox{height: auto; display: grid; place-items: center;text-align:center;}
.EmailVerifyBox h1{font-size: 24px; color: #92BDDF; font-weight: 800;}
.EmailVerifyBox h2{font-size: 30px; color: #92BDDF; font-weight: 800; width: min(100%, 100%); margin: auto;}
.EmailVerifyBoxModel button{width: fit-content ; margin: auto;}
.verifiedMail{background-color: var(--purple);}
.verifiedMail h2{color: var(--Darkgray);}
.verifiedMail p{color: var(--Darkgray);}
.verifiedMail{height: 100vh;}

/*.EmailVerifyBox{
  text-align:center;
  width:50%;
  height:100%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius:10px;
  margin-top:7%;
  margin-bottom:2%; position: relative; overflow: hidden;
  background-repeat:no-repeat; z-index: 0;
  
}
.EmailVerifyBox::before{content: ''; width: 200%; aspect-ratio: 1/1;     border-radius: 50%;
  transform: translate(-25%, -90%); z-index: -1;
background: #c3e0fd;  position: absolute; left: 0; top: 0;}
.EmailVerifyBox img{
  width: 100px;
    border-radius: 100px;
    border: 2px solid #fff;
    background: #fff;
    padding: 5px;
}
.btnrm{
  margin-bottom: 2rem;
}
.EmailVerifyBox h1{
  padding-top:1.5rem;
}
.EmailVerifyBox .pmg{
  padding-bottom: 2rem;
}*/